import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainPage from './routes/MainPage/MainPage';
import ErrorPage from './routes/ErrorPage/ErrorPage';

import img404 from './assets/img/icons/404.svg';
import img500 from './assets/img/icons/500.svg';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} errorElement={<ErrorPage title={'Page Not Found'} description={'You can back to the homepage'} img={img404} />}></Route>
        <Route path="*" element={<ErrorPage title={'Page Not Found'} description={'You can back to the homepage'} img={img404} />}></Route>
        <Route path="500" element={<ErrorPage title={'Server Error'} description={'Сonnection with a server is lost. Please, go back to the homepage'} img={img500} />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
