import { Link } from 'react-router-dom';

import './ErrorPage.css';

export default function ErrorPage(props) {
  return (
    <div className="error-page">
      <main className="error-page-main">
        <div>
          <h1 className="f-center">{props.title}</h1>
          <p className="f-20 f-center">{props.description}</p>
        </div>
        <img src={props.img} alt="error img" width={890} height={366} />
        <Link to="/" className="btn-underline f-18">
          Go to homepage
        </Link>
      </main>
    </div>
  );
}
