import { arrayNotifications } from '../../utils/supportConstants';

import './Notifications.css';

function Notifications() {
  return (
    <div className="notification-container">
      {arrayNotifications &&
        arrayNotifications.map((el) => (
          <div className="notification" key={el.id}>
            <div className="notification-text">
              <p className={el.class}>{el.title}</p>
              <p>{el.description}</p>
            </div>
            <p className="notification-time">{el.time}</p>
          </div>
        ))}
    </div>
  );
}

export default Notifications;
