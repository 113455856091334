import Header from '../../components/Header/Header.jsx';
import Notifications from '../../components/Notifications/Notifications.jsx';

import iphoneNotifications from '../../assets/img/iphone/iphone_notifications.webp';
import download from '../../assets/img/icons/download.svg';
import video from '../../assets/for_site_1.mp4';
import videoWebm from '../../assets/mockup_2.webm';
import { appLink } from '../../oneLinkSmart';


import './MainPage.css';

function MainPage() {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <div className="main-page">
      <Header appLink={appLink} />
      <main className="main-page-main">
        <div className="wrapper main-wrapper">
          <div className="left-part">
            <h1>Track & Analyze Instagram Profile</h1>
            <p className="main-page-text">
              Get accurate and detailed reports on your followers, unfollowers, admirers, guests and
              more. Discover useful and powerful tool to promote your account!
            </p>
            <a
              href={appLink}
              target="_blank"
              rel="noreferrer"
              className="download-container"
            >
              <img src={download} alt="download" width={210} height={71} className="download" />
            </a>
          </div>
          <div className="iphone-container">
            <img
              src={iphoneNotifications}
              alt="iphone notifications"
              className="iphone-notifications"
              width={251}
              height={490}
            />
            <div className="iphone-video-container">
              {isSafari ? (
                <img
                  autoPlay="autoPlay"
                  preload="auto"
                  playsInline
                  muted="muted"
                  className="iphone-video"
                  loop="loop"
                  src={video}
                  alt="iphone video"
                  width={427}
                  height={784}
                />
              ) : (
                <video
                  className="iphone-video"
                  width={427}
                  height={784}
                  preload="auto"
                  autoPlay
                  playsInline
                  loop
                  muted
                >
                  <source src={videoWebm} type="video/webm" />
                </video>
              )}
            </div>
          </div>
          <div className="right-part">
            <div className="right-part-background"></div>
            <Notifications />
          </div>
        </div>
      </main>
    </div>
  );
}

export default MainPage;
